<template>
  <div id="wrapper">
    <div id="header">
      <img id="logo" :src="require('@/assets/img/logo/full_logo.png')" />
    </div>
    <div id="main-wrapper">
      <div id="main">
        <img class="login-left" :src="require('@/assets/img/login/main.png')" />
        <div class="login-right">
          <div v-show="isLogin" :style="{ width: '330px', margin: '0 auto' }">
            <div :style="{ height: '52px' }">
              <img :style="{ width: '100%', height: '100%' }" :src="require('@/assets/img/login/login_title.png')" />
            </div>
            <div
              style="height: 40px; margin: 0px auto; font-size: 16px; font-weight: 400; padding: 36px 0 20px 0; width: 240px">
              <span style="margin-right: 80px; cursor: pointer"
                :class="{ loginActive: loginForm.loginType != 'mobile' }"
                @click="loginForm.loginType = 'user'; isUserLoginError = false;">账号登录</span>
              <span style="cursor: pointer" :class="{ loginActive: loginForm.loginType == 'mobile' }"
                @click="loginForm.loginType = 'mobile'; isMobileLoginError = false;">短信登录</span>
            </div>
            <div v-show="loginForm.loginType == 'user'">
              <div v-show="isUserLoginError" class="row-block error-block">
                <i class="el-icon-warning" />
                {{ userLoginError }}
              </div>
              <div class="row-block">
                <el-input placeholder="请输入手机号" prefix-icon="el-icon-mobile-phone"
                  v-model="loginForm.userMobile"></el-input>
              </div>
              <div class="row-block">
                <el-input :type="showLoginPassword ? 'text' : 'password'" placeholder="请输入密码" prefix-icon="el-icon-lock"
                  v-model="loginForm.password">
                  <i slot="suffix" :class="[showLoginPassword ? 'ri-eye-line' : 'ri-eye-close-line']"
                    @click="showLoginPassword = !showLoginPassword"></i>
                </el-input>
              </div>
            </div>
            <div v-show="loginForm.loginType == 'mobile'">
              <div v-show="isMobileLoginError" class="row-block error-block">
                <i class="el-icon-warning" />
                {{ mobileLoginError }}
              </div>
              <div class="row-block">
                <el-input placeholder="请输入手机号" prefix-icon="el-icon-mobile-phone" v-model="loginForm.mobile"></el-input>
              </div>
              <div class="row-block">
                <el-input placeholder="请输入验证码" prefix-icon="el-icon-message" maxlength="6"
                  oninput="value = value.replace(/[^\d]/g, '')" v-model="loginForm.verifyCode">
                  <span v-show="!isLoginCountDown" slot="suffix" show-word-limit @click="getVerifyCode"
                    style="color: #66b1ff"> 获取验证码 </span>
                  <span v-show="isLoginCountDown" slot="suffix" style="color: #66b1ff"> {{ loginCountDown }}秒后可重新获取
                  </span>
                </el-input>
              </div>
            </div>
            <div class="row-block">
              <el-button type="primary" @click="login">登录</el-button>
            </div>
            <div class="row-block end-block">
              <el-checkbox :style="{ float: 'left' }" @change="changeRemember" v-model="isRemember">记住密码</el-checkbox>
              <span @click="isLogin = false; initRegister();">
                注册新账号
              </span>
            </div>
          </div>
          <div v-show="!isLogin" :style="{ width: '330px', margin: '0 auto' }" class="register-block">
            <div
              style="height: 50px; line-height: 50px; font-size: 16px; font-weight: 600; color: #0068cd; position: relative">
              用户注册
              <span v-show="isRegisterError" class="register-error"> <i class="el-icon-warning-outline" />{{
        registerError }} </span>
            </div>
            <div class="row-block">
              <el-input placeholder="请输入手机号" prefix-icon="el-icon-mobile-phone"
                oninput="value = value.replace(/[^\d]/g, '')" v-model.number="registerForm.mobile"></el-input>
            </div>
            <div class="row-block">
              <el-input placeholder="请输入验证码" prefix-icon="el-icon-message" maxlength="6"
                oninput="value = value.replace(/[^\d]/g, '')" v-model="registerForm.verifyCode">
                <span v-show="!isRegisterCountDown" slot="suffix" @click="getVerifyCode" style="color: #66b1ff"> 获取验证码
                </span>
                <span v-show="isRegisterCountDown" slot="suffix" style="color: #66b1ff"> {{ registerCountDown }}秒后可重新获取
                </span>
              </el-input>
            </div>
            <div class="row-block">
              <el-input :type="showRegisterPassword ? 'text' : 'password'" placeholder="密码至少6个字符且包含数字、字母大小写"
                prefix-icon="el-icon-lock" v-model="registerForm.password">
                <i slot="suffix" :class="[showRegisterPassword ? 'ri-eye-line' : 'ri-eye-close-line']"
                  @click="showRegisterPassword = !showRegisterPassword"></i>
              </el-input>
            </div>
            <div class="row-block">
              <el-input :type="showComfirmPassword ? 'text' : 'password'" placeholder="确认密码" prefix-icon="el-icon-lock"
                v-model="registerForm.comfirmPassword">
                <i slot="suffix" :class="[showComfirmPassword ? 'ri-eye-line' : 'ri-eye-close-line']"
                  @click="showComfirmPassword = !showComfirmPassword"></i>
              </el-input>
            </div>
            <div class="row-block">
              <el-input placeholder="请输入邀请码" prefix-icon="ri-user-add-line" maxlength="6"
                oninput="value = value.replace(/[^0-9a-zA-Z]/g, '')" v-model="registerForm.inviteCode">
                <span slot="suffix" @click="getCustomerService" style="color: #66b1ff"> 添加客服微信获取 </span>
              </el-input>
            </div>
            <div class="row-block">
              <el-checkbox v-model="agree"></el-checkbox>
              已阅读并同意
              <el-link type="primary" :underline="false" @click="dialogVisible = true"> 《云果仓SaaS-ERP申请账号协议》 </el-link>
            </div>
            <div class="row-block">
              <el-button class="submit" type="primary" @click="register">注册</el-button>
            </div>
            <div class="row-block end-block">
              <span @click="isLogin = true; initLogin();">
                已有账号，去登录
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="footer-wrapper">
      <div id="footer">
        <span>Copyright ©{{ dateYear }} {{ title }} {{ domian }}</span>
        <el-divider direction="vertical" />
        <a :href="ICPLink" target="_blank"> {{ ICP }}</a>
        <el-divider direction="vertical" />
        <a :href="networkSecurityLink" target="_blank">
          <img :src="networkSecurityImg" :title="networkSecurity" />
          {{ networkSecurity }}
        </a>
      </div>
    </div>
    <el-dialog title="" :visible.sync="customerServiceDialogVisible" width="400px" class="customer-service">
      <img :src="customerServiceImg" />
    </el-dialog>
    <el-dialog title="用户隐私和协议条款" :visible.sync="dialogVisible" width="680px">
      <div style="width: 100%; height: 300px">
        <p>
          尊敬的用户，欢迎您使用云果仓-SaaS ERP！我司云创联航科技有限公司（以下简称“云创联航”）特别提示：在使用云果仓-SaaS ERP之前，您应当认真阅读并遵守《云果仓-SaaS
          ERP申请账户协议》（以下简称“本协议”），请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、争议解决和法律适用条款。如您对协议有任何疑问的，应向云创联航系统客服咨询。当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，即表示您已充分阅读、理解并接受本协议的全部内容，并与云创联航达成协议。您承诺接受并遵守本协议的约定，届时您不应以未阅读本协议的内容或者未获得云创联航对您问询的解答等理由，主张本协议无效，或要求撤销本协议。
        </p>
        <p>一、签约主体</p>
        <p>1.1、本许可协议的缔约方为云创联航和用户（“您”），本许可协议具有合同效力。</p>
        <p>二、软件许可方式</p>
        <p>
          2.1、用户和云创联航线下签订《云创联航SaaS ERP服务协议书》（软件许可费及许可期限以此协议书为准），如涉及线上店铺经营，用户在相应服务市场选择云创联航SaaS ERP版本下单购买，云创联航根据《云创联航SaaS
          ERP服务协议书》中约定的软件许可费，对服务市场购买支付金额扣除平台手续费后返款给用户。未与云创联航签订《云创联航SaaS
          ERP服务协议书》、但属于云创联航限定范围内的免费用户，则在连续12个月内自发货超2万单后需和云创联航签订《云创联航SaaS
          ERP服务协议书》。（推单给其他云创联航ERP用户发货的部分不属于自发货，具体统计口径请联系云创联航）
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false; agree = true;">
          同意协议
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { submit } from '@/api/base';
import { domain, ICP, ICPLink, NetworkSecurityLink, NetworkSecurityImg, NetworkSecurity, noImgUrl } from '@/config';
import { checkPassword, checkMobile } from '@/utils/validate';

export default {
  name: 'login',
  data() {
    return {
      dateYear: new Date().getFullYear(),
      title: this.$baseTitle,
      domian: domain,
      ICP: ICP,
      ICPLink: ICPLink,
      networkSecurityLink: NetworkSecurityLink,
      networkSecurityImg: NetworkSecurityImg,
      networkSecurity: NetworkSecurity,
      isLogin: true,
      isRemember: true, //默认记住密码
      showLoginPassword: false,
      showRegisterPassword: false,
      showComfirmPassword: false,
      isLoginCountDown: false,
      loginCountDown: 60,
      isRegisterCountDown: false,
      registerCountDown: 60,
      isMobileLoginError: false,
      mobileLoginError: '',
      isUserLoginError: false,
      userLoginError: '',
      isRegisterError: false,
      registerError: '',
      loginForm: {
        loginType: 'user',
        userMobile: '',
        password: '',
        mobile: '',
        verifyCode: '',
      },
      registerForm: {
        mobile: '',
        verifyCode: '',
        password: '',
        comfirmPassword: '',
        inviteCode: '',
      },
      loading: false,
      customerServiceImg: '',
      customerServiceDialogVisible: false,
      dialogVisible: false,
      agree: false,
    };
  },
  async beforeCreate() {
    if (this.$route.query.token && this.$route.query.redirect) {
      await this.$store.dispatch('user/setAccessToken', this.$route.query);
      this.$router.push({ name: this.$route.query.redirect }).catch(() => { });
    }
    document.querySelector('body').style = "background:url('/static/img/background.png') no-repeat;background-size:100% 100%;background-attachment:fixed;margin:0;";
  },
  beforeDestroy() {
    document.querySelector('body').removeAttribute('style');
  },
  mounted() {
    if (this.$cookies.get('xbt_remember') == 'Y') {
      this.isRemember = true;
      this.loginForm.userMobile = this.$cookies.get('user_mobile');
      this.loginForm.password = this.$cookies.get('user_pwd');
    }
  },
  methods: {
    initLogin() {
      this.loginForm.loginType = 'user';
      this.loginForm.userMobile = '';
      this.loginForm.password = '';
      this.loginForm.mobile = '';
      this.loginForm.verifyCode = '';
    },
    initRegister() {
      this.agree = false;
      this.registerForm.mobile = '';
      this.registerForm.verifyCode = '';
      this.registerForm.password = '';
      this.registerForm.comfirmPassword = '';
      this.registerForm.inviteCode = '';
    },
    changeRemember() {
      if (!this.isRemember) {
        this.$cookies.remove('user_mobile');
        this.$cookies.remove('user_pwd');
        this.$cookies.remove('xbt_remember');
      }
    },
    async getVerifyCode() {
      this.isMobileLoginError = false;
      this.mobileLoginError = '';
      this.isRegisterError = false;
      this.registerError = '';
      const mobile = this.isLogin ? this.loginForm.mobile : this.registerForm.mobile;
      if (!mobile) {
        if (this.isLogin) {
          this.isMobileLoginError = true;
          this.mobileLoginError = '手机号不可为空';
          return;
        } else {
          this.isRegisterError = true;
          this.registerError = '手机号不可为空';
          return;
        }
      }
      if (!checkMobile(mobile)) {
        if (this.isLogin) {
          this.isMobileLoginError = true;
          this.mobileLoginError = '手机号格式不正确';
          return;
        } else {
          this.isRegisterError = true;
          this.registerError = '手机号格式不正确';
          return;
        }
      }
      await submit('/api/user/sendVerifycode', {
        type: this.isLogin ? 0 : 1,
        mobile: mobile,
      });
      if (this.isLogin) {
        this.loginForm.verifyCode = '';
        this.isLoginCountDown = true;
        this.loginCountDown = 60;
      } else {
        this.registerForm.verifyCode = '';
        this.isRegisterCountDown = true;
        this.registerCountDown = 60;
      }
      this.countDown(this.isLogin);
      this.$baseMessage('已发送验证码成功', 'success');
    },
    countDown(isLogin) {
      setTimeout(() => {
        if (isLogin) {
          this.loginCountDown--;
          if (this.loginCountDown > 0) {
            this.countDown(isLogin);
          } else {
            this.isLoginCountDown = false;
          }
        } else {
          this.registerCountDown--;
          if (this.registerCountDown > 0) {
            this.countDown(isLogin);
          } else {
            this.isRegisterCountDown = false;
          }
        }
      }, 1000);
    },
    login() {
      this.isUserLoginError = false;
      this.userLoginError = '';
      this.isMobileLoginError = false;
      this.mobileLoginError = '';
      if (this.loginForm.loginType == 'user') {
        if (!this.loginForm.userMobile) {
          this.isUserLoginError = true;
          this.userLoginError = '手机号不可为空';
          return;
        }
        if (!checkMobile(this.loginForm.userMobile)) {
          this.isUserLoginError = true;
          this.userLoginError = '手机号格式不正确';
          return;
        }
        if (!this.loginForm.password) {
          this.isUserLoginError = true;
          this.userLoginError = '密码不可为空';
          return;
        }
      } else {
        if (!this.loginForm.mobile) {
          this.isMobileLoginError = true;
          this.mobileLoginError = '手机号不可为空';
          return;
        }
        if (!checkMobile(this.loginForm.mobile)) {
          this.isMobileLoginError = true;
          this.mobileLoginError = '手机号格式不正确';
          return;
        }
        if (!this.loginForm.verifyCode) {
          this.isMobileLoginError = true;
          this.mobileLoginError = '校验码不可为空';
          return;
        }
      }
      this.loading = true;
      this.$store
        .dispatch('user/login', { ...this.loginForm })
        .then(() => {
          //登录成功设置cookie
          if (this.isRemember) {
            if (this.loginForm.userMobile && this.loginForm.password) {
              this.$cookies.set('user_mobile', this.loginForm.userMobile, '1y');
              this.$cookies.set('user_pwd', this.loginForm.password, '1y');
              this.$cookies.set('xbt_remember', 'Y', '1y');
            }
          } else {
            this.$cookies.remove('user_mobile');
            this.$cookies.remove('user_pwd');
            this.$cookies.remove('xbt_remember');
          }
          let redirect = null;
          if (this.$route.query.redirect) {
            redirect = this.$route.query.redirect;
            if (redirect === '/404' || redirect === '/401') {
              redirect = '/';
            }
          }
          this.loading = false;
          this.$router.push(redirect ? redirect : { name: this.$store.getters['user/loginRedirect'] }).catch(() => { });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    async getCustomerService() {
      if (!this.customerServiceImg) {
        const { data } = await submit('/api/user/getCustomerService');
        this.customerServiceImg = data && data.Url ? data.Url : noImgUrl;
      }
      this.customerServiceDialogVisible = true;
    },
    async register() {
      this.isRegisterError = false;
      this.registerError = '';
      if (!this.registerForm.mobile) {
        this.isRegisterError = true;
        this.registerError = '手机号不可为空';
        return;
      }
      if (!checkMobile(this.registerForm.mobile)) {
        this.isRegisterError = true;
        this.registerError = '手机号格式不正确';
        return;
      }
      if (!this.registerForm.verifyCode) {
        this.isRegisterError = true;
        this.registerError = '验证码不可为空';
        return;
      }
      if (!this.registerForm.password) {
        this.isRegisterError = true;
        this.registerError = '密码不可为空';
        return;
      }
      if (!checkPassword(this.registerForm.password)) {
        this.isRegisterError = true;
        this.registerError = '密码至少6个字符且包含数字、字母大小写';
        return;
      }
      if (this.registerForm.password != this.registerForm.comfirmPassword) {
        this.isRegisterError = true;
        this.registerError = '两次密码不一样';
        return;
      }
      if (!this.registerForm.inviteCode) {
        this.isRegisterError = true;
        this.registerError = '邀请码不可为空';
        return;
      }
      if (!this.agree) {
        this.isRegisterError = true;
        this.registerError = '请先阅读协议并勾选同意';
        return;
      }
      await submit('/api/user/register', this.registerForm);
      this.$baseMessage('注册成功', 'success');
      setTimeout(() => {
        this.loginForm.loginType = 'user';
        this.loginForm.userMobile = this.registerForm.mobile;
        this.loginForm.password = this.registerForm.password;
        this.login();
      }, 2000);
    },
  },
};
</script>

<style lang="scss" scoped>
#wrapper {
  height: 100%;
  width: 100%;

  ::v-deep {

    .el-input__inner,
    .el-button {
      height: 40px !important;
      width: 100%;
      font-size: 14px;
    }

    .el-input__icon {
      font-size: 18px;
      display: inline-block;
    }

    .el-input__suffix {
      line-height: 40px;
      cursor: pointer;

      i {
        font-size: 18px;
        opacity: 0.8;
        cursor: pointer;
      }
    }

    .el-dialog__body {
      font-size: 14px;
      font-weight: 400;
      color: #000000;
      line-height: 22px;
    }

    .el-dialog__footer {
      text-align: center;

      .el-button {
        width: 300px;
      }
    }

    .el-link.el-link--default {
      color: white !important;
    }
  }

  .customer-service {
    img {
      width: 300px;
      height: 300px;
      border: 3px solid white;
    }

    ::v-deep {
      .el-dialog {
        background: none;
        box-shadow: none;

        .el-dialog__header {
          .el-icon-close::before {
            color: white;
            font-size: 30px;
            font-weight: bold;
            border: 2px solid white;
            border-radius: 30px;
          }
        }

        .el-dialog__body {
          border-top: none;
        }
      }
    }
  }
}

#header {
  height: 13%;
  min-height: 85px;
  position: relative;

  #logo {

    height: 70px;
    position: absolute;
    bottom: 0;
    left: 50px;
  }
}

#main-wrapper {
  height: 77%;
  min-height: 540px;
  position: relative;

  #main {
    width: 1200px;
    height: 440px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -220px;
    margin-left: -600px;

    .login-left {
      float: left;
      width: 806px;
      height: 440px;
    }

    .login-right {
      float: left;
      height: 440px;
      width: 394px;
      background: white;
      position: relative;

      .loginActive {
        color: #0075e3;
        position: relative;

        &::after {
          content: '';
          height: 1px;
          width: 64px;
          border-bottom: 2px solid #0075e3;
          position: absolute;
          top: 22px;
          left: 0;
        }
      }

      .row-block {
        margin-bottom: 18px;
        width: 100%;

        &.end-block {
          text-align: right;
          margin-top: 28px;

          span {
            margin-left: 15px;
            cursor: pointer;
          }
        }
      }

      .register-block {
        .row-block {
          margin-bottom: 12px;
        }

        .end-block {
          margin-top: 0;
        }
      }

      .register-error {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 12px;
        font-weight: 400;
        color: #ff0000;

        i {
          font-size: 14px;
          font-weight: 600;
          margin-right: 3px;
        }
      }

      .error-block {
        border: 1px solid #ff6185;
        background: #ffecf0;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        color: #f52554;
        padding: 0 5px;
        box-sizing: border-box;
        border-radius: 4px;

        i {
          font-size: 16px;
        }
      }
    }
  }
}

#footer-wrapper {
  height: 10%;
  min-height: 40px;
  position: relative;

  #footer {
    height: 40px;
    width: 100%;
    position: absolute;
    bottom: 0;
    color: white;
    line-height: 40px;
    text-align: center;
    background: rgba(0, 0, 0, 0.08);

    a {
      color: white;

      img {
        vertical-align: middle;
        height: 20px;
        width: 20px;
      }
    }
  }
}
</style>
